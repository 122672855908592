<template>
  <div>
        <template>
            <vue-excel-editor v-model="jsondata">
                <vue-excel-column v-bind:key="column.id" v-for="column in columns" :field="column.field"   :label="column.field" />
            </vue-excel-editor>
        </template>
        <p>This is a beta version and needs r-assessing.</p>
        <p>Notes: i would form this. Then look at changing the row records to be just lists of fields Liststring so the row looks like ['','','']. Then i would look to allow more easily editing of the field name. I would refer to each cell ass rowIndex.columnIndex </p>
  </div>
</template>

<script>
export default {
    methods: {
        getView (){ this.$http.get('/ViewAccess/sheet')
        .then(() => { 
        })
        .catch(() => { 
        })},
    },
 created () {
      this.getView()
    // this.$refs.grid.newRecord()
 },
 data () {
     return {
         jsondata: [],
         columns: [{id:1, field: 'A'},{id:1, field: 'B'},{id:1, field: 'C'},{id:1, field: 'D'},{id:1, field: 'E'},{id:1, field: 'F'},{id:1, field: 'G'},{id:1, field: 'H'},{id:1, field: 'I'},{id:1, field: 'J'},{id:1, field: 'K'},{id:1, field: 'L'},{id:1, field: 'M'},{id:1, field: 'N'},{id:1, field: 'O'},{id:1, field: 'P'},{id:1, field: 'Q'},{id:1, field: 'S'}]
     }
 }
}
</script>

<style>

</style>